import { Dispatch } from '@reduxjs/toolkit';
import { Flex, Icon } from '@webkom/lego-bricks';
import { debounce } from 'lodash-es';
import { Search } from 'lucide-react';
import {
  useEffect,
  useMemo,
  useState,
  type KeyboardEventHandler,
  type SetStateAction,
} from 'react';
import ae6b45f32bf045d25a6d6ea7bdab8624 from '~/assets/yuleog-bc80d0645d1ecd3778db41d9b7551d9b/ae6b45f32bf045d25a6d6ea7bdab8624.png';
import { useFeatureFlag } from '~/utils/useFeatureFlag';
import styles from './Search.module.css';

const hash = async (s: string): Promise<string> => {
  const encoder = new TextEncoder();
  const arrayBuffer = await window.crypto.subtle.digest(
    'SHA-1',
    encoder.encode(s),
  );
  const hashArray = Array.from(new Uint8Array(arrayBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

type Props = {
  query: string;
  onQueryChanged: Dispatch<SetStateAction<string>>;
  handleKeyDown: KeyboardEventHandler<HTMLElement>;
};

const searchFields = [
  {
    className: styles.inputElementNormal,
    autoFocus: true,
  },
  {
    className: styles.inputElementMobile,
    autoFocus: false,
  },
];

const SearchBar = ({ query, onQueryChanged, handleKeyDown }: Props) => {
  const isEaster = useFeatureFlag('easter2025');
  const [useIndicator, setUseIndicator] = useState(false);

  const debouncedIndicator = useMemo(
    () =>
      debounce(async (query: string) => {
        const queryHash = await hash(query.toLowerCase());
        if (queryHash === 'e21f19dcac86df575081cd57d62ce436c0bef492') {
          setUseIndicator(true);
        } else {
          setUseIndicator(false);
        }
      }, 300),
    [],
  );

  useEffect(() => {
    debouncedIndicator(query);
  }, [query, debouncedIndicator]);

  return (
    <Flex
      className={styles.inputContainer}
      justifyContent="space-between"
      alignItems="center"
    >
      <div className={styles.searchIcon}>
        {isEaster && useIndicator ? (
          <img
            style={{ maxHeight: '50px' }}
            src={ae6b45f32bf045d25a6d6ea7bdab8624}
          />
        ) : (
          <Icon iconNode={<Search />} size={30} />
        )}
      </div>
      {searchFields.map((k) => (
        <input
          key={`search-field-${k.autoFocus ? 'on' : 'off'}`}
          onKeyDown={handleKeyDown}
          className={k.className}
          onChange={(e) => onQueryChanged(e.target.value)}
          value={query}
          size={1}
          placeholder="Hva leter du etter?"
          ref={(input) => input && k.autoFocus && input.focus()}
        />
      ))}
    </Flex>
  );
};

export default SearchBar;
